import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from 'egenie-common';
import { request, SlideVerify } from 'egenie-common';
import { isString } from 'lodash';
import { action, observable } from 'mobx';
import React from 'react';
import { api } from '../../../utils';
import type { LoginForm, LoginParams, LoginResponse, Response } from '../interface';

export class Store {
  @observable public username: string;

  @observable public password: string;

  @observable public smsCode: string;

  @observable public errorInfo: string;

  @observable public loginFormRef = React.createRef<FormInstance>();

  @observable public authimageneed = false;

  @observable public validateImage: number = new Date().getTime();

  @observable public shopId = undefined; // 店铺id

  @observable public accountType = undefined; // 用户身份类型

  @observable public tenantModule = ''; // 系统类型

  @observable public sysType = '';

  @observable public loginPath = ''; // 登录path

  @observable public IpRisk = false;

  @observable public riskData = null;

  @observable public countDown = 60;

  @observable public timer;

  @observable public isSendCode = false;

  @action public init = (): void => {
    this.authimageneed = false;
    this.errorInfo = '';
  };

  public LoginResponse = action(() => {
    request({ url: api.getAuthImage })
      .then(() => {
        this.validateImage = new Date().getTime();
      });
  });

  public handleLogin = action((data: LoginForm) => {
    console.log('risk...', this.IpRisk);
    let params: LoginParams = {
      username: data.username,
      password: data.password,
      imageCode: data.authImage,
      browserCode: localStorage.browserCode,
      shopId: this.shopId,
      accountType: this.accountType,
      tenantModule: this.tenantModule,
      sysType: this.sysType,
      appType: 'pc', // 设备类型
    };
    if (this.IpRisk) {
      params = {
        accountType: this.accountType,
        appType: 'pc', // 设备类型
        browserCode: localStorage.browserCode,
        mobile: this.riskData.mobile,
        smsCode: data.smsCode,
        sysType: this.sysType,
        tenantModuleSet: this.tenantModule,
        username: this.riskData.username,
      };
    }

    request({
      method: 'post',
      url: this.IpRisk ? api.loginByIam : api.login,
      data: params,
      headers: { 'Login-Page': this.loginPath },
    })
      .then((res: BaseData<LoginResponse | string>) => {
        console.log('🚀 ~ file: store.tsx ~ line 62 ~ Store ~ handleLogin=action ~ res', res.data);
        !isString(res.data) && this.dealLoginResult(res.data);
        this.authimageneed = false;
        this.IpRisk = false;
        this.errorInfo = '';
      })
      .catch((res) => {
        message.destroy();
        this.handleLoginError(res.data, data);
      });
  });

  public handleLoginError = action((res: BaseData<LoginResponse | string>, params: LoginForm): void => {
    console.log('error', res);
    if (!res) {
      this.errorInfo = '网络错误,请联系管理员';
      return null;
    }
    this.errorInfo = res.info;
    if (res.status === 'AuthImageNeed') {
      this.LoginResponse();
      this.authimageneed = true;
    } else if (res.status === 'send_again') {
      this.handleLogin(params);
    } else if (res.status === 'AccountRisk') {
      isString(res.data) && window.open(res.data);
    } else if (res.status === 'IpRisk') {
      this.IpRisk = true;
      this.riskData = res.data;
    }
  });

  public dealLoginResult = (result: LoginResponse): void => {
    const exp = new Date();
    exp.setTime(exp.getTime() + 24 * 60 * 60 * 1000);
    if (result.changeDomain) {
      window.location.assign(`${result.forwardDomain}/api/iam/index/forward?sessionId=${result.sessionId}&code=${result.code}`);
    } else {
      window.location.assign(result.indexUrl);
    }
  };

  //  表单验证
  public handleFieldsChange = action((changeFields, allFields) => {
    // 解决后端错误信息与前端表单错误重叠
    this.errorInfo = '';
    if (!changeFields.length) {
      return;
    }
    if (!changeFields[0].value) {
      this.errorInfo = '';
    }
  });

  @action
  public setIpRisk = () => {
    this.IpRisk = false;
  };

  public showImageCode = (id: string, x: number, y: number): void => {
    if (!this.riskData.mobile) {
      message.error('请输入手机号');
      return;
    }
    console.log('x,y', x, y);
    new SlideVerify({
      width: 240,
      height: 120,
      x,
      y,
      parentId: id,
      sliderText: '拖动滑块完成拼图，获取手机验证码',
      onSuccess: () => {
        // 发送验证码
        request({
          data: {
            mobile: this.riskData.mobile,
            module: this.tenantModule,
            username: this.riskData.username,
          },
          method: 'post',
          url: api.sendIAMCode,
        })
          .then((res: Response) => {
            this.isSendCode = true;
            this.countDown = 60;
            this.handleCountDown();
          })
          .catch((res: Response) => {
            message.error(res.data.data);
          });
      },
    });
  }; // 倒计时

  private handleCountDown = action(() => {
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      this.countDown -= 1;
      if (this.countDown < 1) {
        clearInterval(this.timer);
        this.isSendCode = false;
      }
    }, 1000);
  });
}
