import { Provider } from 'mobx-react';
import React from 'react';
import type { Props } from '../interface';
import Login from './login';
import { Store } from './store';

const store = new Store();
export const LoginForm: React.FC<Props> = (props: Props) => {
  store.shopId = props.shopId ? Number(props.shopId) : undefined;
  store.accountType = props.accountType;
  store.tenantModule = props.tenantModule || '';
  store.sysType = props.sysType || '';
  store.loginPath = props.loginPath || '';
  return (
    <Provider store={store}>
      <Login
        {...props}
      />
    </Provider>
  );
};

