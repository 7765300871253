import { Button, Col, Form, Input, Row } from 'antd';
import { history } from 'egenie-common';
import { inject, observer } from 'mobx-react';
import React from 'react';
import type { Props } from '../interface';
import styles from './index.less';

if (!localStorage.browserCode) {
  const agent = navigator.userAgent.toLowerCase();
  const regStrChrome = /chrome\/[\d.]+/gi;
  if (agent.indexOf('chrome') > 0) {
    const chromeCode = agent.match(regStrChrome);
    localStorage.browserCode = chromeCode[0];
  }
}
@inject('store')
@observer
export default class LoginContent extends React.Component<Props> {
  componentDidMount() {
    this.props.store.init();
  }

  public handleSubmit(values: { username: string; password: string; smsCode?; }): void {
    const { handleLogin } = this.props.store;
    return handleLogin(values);
  }

  public phoneHide = (phone) => {
    const reg = /^(1[3-9][0-9])\d{4}(\d{4}$)/; // 定义手机号正则表达式
    phone = phone.replace(reg, '$1****$2');
    return phone; // 输出为131****1234
  };

  // 验证码
  public getCode = (username: string, mobile: string): JSX.Element => {
    const {
      isSendCode,
      showImageCode,
      countDown,
    } = this.props.store;
    const hidePhoneText = this.phoneHide(mobile);
    return (

      // 此Id提供给图形验证码
      <Row id="loginBySMS">
        <Col span={24}>
          <div className={styles.tips}>
            <span>
              为了您的账号安全，本次登陆需要短信验证。
            </span>
            <div className={`${styles.flex}`}>
              <div>
                账号：
                {username}
              </div>
              <div>
                ，手机号：
                {hidePhoneText}
              </div>
            </div>
          </div>
        </Col>
        <Col span={16}>
          <Input
            className={styles.verifyCode}
            placeholder="请输入验证码"
          />
        </Col>
        <Col span={8}>
          <Button
            className={styles.smscode}
            disabled={isSendCode}
            onClick={showImageCode.bind(this, 'loginBySMS', 134, -192)}
            type="primary"
          >
            {isSendCode ? `${countDown}S后重新获取` : '获取验证码'}
          </Button>
        </Col>
      </Row>
    );
  };

  render(): JSX.Element {
    const {
      errorInfo,
      loginFormRef,
      authimageneed,
      validateImage,
      changeAuthImage,
      handleFieldsChange,
      IpRisk,
      riskData,
      setIpRisk,
    } = this.props.store;
    const {
      loginPath,
      registryPath,
      changePasswordPath,
      accountType,
    } = this.props;
    return (
      <div className={styles.loginForm}>
        <div className={styles.content}>
          <div className={styles.title}>
            账号登录
          </div>
          <div className={styles.form}>
            <Form
              onFieldsChange={handleFieldsChange}
              onFinish={this.handleSubmit.bind(this)}
              ref={loginFormRef}
            >
              {!IpRisk && (
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: '请输入账号',
                    },
                  ]}
                >
                  <Input
                    autoComplete="on"
                    className={styles.input}
                    placeholder="请输入账号"
                  />
                </Form.Item>
              )}
              {!IpRisk && (
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: '请输入密码',
                    },
                  ]}
                >
                  <Input
                    className={styles.input}
                    placeholder="请输入密码"
                    type="password"
                  />
                </Form.Item>
              )}
              {authimageneed && !IpRisk && (
                <Form.Item
                  name="authImage"
                  rules={[
                    {
                      required: true,
                      message: '请录入图片验证码!',
                    },
                  ]}
                >
                  <div>
                    <Input
                      className={styles.input}
                      placeholder="验证码，不区分大小写"
                    />
                    <img
                      onClick={changeAuthImage}
                      src={`/api/iac/authImage/anon/getAuthImage?${validateImage}`}
                      title="看不清，换一张"
                    />
                  </div>
                </Form.Item>
              )}
              {IpRisk && (
                <Form.Item
                  name="smsCode"
                  rules={[
                    {
                      required: true,
                      message: '请输入验证码!',
                    },
                  ]}
                >
                  {this.getCode(riskData.username, riskData.mobile)}
                </Form.Item>
              )}
              <Form.Item>
                <Button
                  className={styles.submit}
                  htmlType="submit"
                  type="primary"
                >
                  登录
                </Button>
              </Form.Item>
              <div className={styles.others}>
                <span
                  className={styles.registry}
                  onClick={setIpRisk}
                  style={{ visibility: IpRisk ? 'visible' : 'hidden' }}
                >
                  换个账号登录
                </span>
                <span className={styles.error}>
                  {errorInfo}
                </span>
              </div>
            </Form>
          </div>
          {!IpRisk && (
            <div className={styles.others}>
              <span
                className={styles.registry}
                onClick={() => history.push(registryPath)}
                style={{ visibility: registryPath ? 'visible' : 'hidden' }}
              >
                注册账号
              </span>
              <span
                onClick={() => history.push(`${changePasswordPath}?accountType=${accountType}&loginPath=${loginPath}`)}
                style={{ visibility: changePasswordPath ? 'visible' : 'hidden' }}
              >
                忘记密码
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
