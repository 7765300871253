
import React from 'react';
import { api, CopyRightFooter } from '../../utils';
import { LoginForm } from '../loginForm/login';
import styles from './index.less';

if (!localStorage.browserCode) {
  const agent = navigator.userAgent.toLowerCase();
  const regStrChrome = /chrome\/[\d.]+/gi;
  if (agent.indexOf('chrome') > 0) {
    const chromeCode = agent.match(regStrChrome);
    localStorage.browserCode = chromeCode[0];
  }
}

export default class GetGoodsLogin extends React.Component<{ store?; }> {
  render(): JSX.Element {
    return (
      <div className={styles.login}>
        <div className={styles.leftCircle}/>
        <div className={styles.rightCircle}/>
        <img
          className={styles.background}
          src={`${api.oss}/images/getGoodsBg.png`}
        />
        <div>
          <img
            className={styles.logo}
            src={`${api.oss}/images/getGoodsLogo.png`}
          />
          <LoginForm
            accountType={3}
            changePasswordPath="/egenie-ts-iac/findPassword"
            loginPath="/egenie-ts-iac/pgmsLogin"
            sysType="pc_pgms"
            tenantModule="100019"
          />
        </div>
        <CopyRightFooter/>
      </div>
    );
  }
}
